import request from '@/utils/request'

// 设备押金列表
export function getUserDepositMoneyList () {
  return request({
    url: '/property/deposit/list',
    method: 'GET',
  })
}
// 用户支付押金
export function getUserDepositMoneyPay (data) {
  console.log(data)
  return request({
    url: '/property/userPay/userDepositAlipay',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 新增接入
export function queryNewProcess (data) {
  console.log(data)
  return request({
    url: '/property/userReview',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 待确认接入
export function queryProcessIng (data) {
  console.log(data)
  return request({
    url: '/property/userReview/list',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 档案列表
export function queryProList (data) {
  console.log(data)
  return request({
    url: '/property/userProfile',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 根据roomId查询服务
export function queryUserProfile (roomId) {
  console.log(roomId)
  return request({
    url: '/property/userProfile/' + roomId,
    method: 'GET'
  })
}
// 居民管理 - 查询当前物业下所有服务人员信息
export function queryFamilyList (data) {
  console.log(data)
  return request({
    url: '/property/familyMember/familyList',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 本月新增安装成功
export function queryThisMonthUserProfileList (data) {
  console.log(data)
  return request({
    url: '/property/userProfile/thisMonth',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 本月新增安装成功
export function queryThisMonthUserReviewList (data) {
  console.log(data)
  return request({
    url: '/property/userReview/thisMonth',
    method: 'POST',
    data: data
  })
}
// 居民管理 - 投保信息
export function queryInsureByRoomAll (id,data) {
  return request({
    url: `/property/userInsureInfo/roomIdList/` + id,
    method: 'POST',
    data: data
  })
}

// 居民管理 - 家庭成员列表
export function queryMemberById (id, data) {
  console.log(data)
  return request({
    url: `/property/familyMember/list/${id}`,
    method: 'post',
    data: data

  })
}
// 居民管理 - 通过身份证号进入详情
export function confirmById (No) {
  return request({
    url: `/property/userFamily/acrdNo/${No}`,
    method: 'POST',
  })
}

// 居民管理 - 查询成员信息
export function queryDtById (id) {
  return request({
    url: `/property/familyMember/${id}`,
    method: 'get',
  })
}