<template>
  <el-container>
    <el-dialog
      title="新增接入"
      v-el-drag-dialog
      :visible.sync="addUser"
      width="40%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content" :model="userForm" ref="userForm">
        <div class="input_item">
          <div class="item_vlaue">选择房号</div>
          <div class="item_key">
            <el-cascader ref="cascader" v-model="userForm.roomId" :options="roomList"></el-cascader>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">姓名</div>
          <div class="item_key">
            <el-input v-model="userForm.ownerName" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">电话</div>
          <div class="item_key">
            <el-input v-model="userForm.ownerPhone" placeholder="请输入电话"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">是否物业代缴「现金交易请物业代缴」</div>
          <div class="item_key">
            <el-radio-group v-model="userForm.propertyAgentPay">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="input_item depositBox">
          <div class="deposit_content">
            <div class="d_left">
              <span class="d_l_tit"> {{ depositList[0] && depositList[0]?.depositName }} </span>
              <span class="d_l_dec"> 终止服务时退还 </span>
            </div>
            <div class="d_right">
              <span class="d_l_tit"> ¥{{ depositList[0] && depositList[0]?.depositMoney }} </span>
            </div>
          </div>
          <div>
            <p>
              用户须知：菏康所采用的设备均严格按照国家标准、安全第一、质量保障，是依据大多数家庭使用场景定制而成，设备本身是免费提供，为避免恶意损坏，在申请接入前需缴纳一定金额的押金，感谢您的理解。
            </p>
          </div>
        </div>
      </div>
      <div ref="alipayWap" v-html="alipay" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="clossAddUser">取 消</el-button>
        <el-button type="primary" @click="getPay()">押金支付</el-button>
        <!-- <el-button type="primary" @click="getAddUser()">押金支付</el-button> -->
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
// 设备报警列表
import { getpropertyRoomList } from '@/api/property.js'
// 居民接口
import { getUserDepositMoneyList, getUserDepositMoneyPay } from '@/api/archives.js'
export default {
  props: ['addUser', 'addUserInfo'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      alipay: '',
      userForm: {
        ownerName: '',
        ownerPhone: '',
        roomId: '',
        propertyAgentPay: 0,
        depositId: ''
      },
      value: [],
      roomList: [],
      depositList: [],
      payForm: ''
    }
  },
  mounted() {
    this.getPropertyRoomList()
    this.getDepositMoneyList()
  },
  methods: {
    async getDepositMoneyList() {
      let res = await getUserDepositMoneyList()
      if (res.success) {
        this.depositList = res.data.filter((i) => i.depositName == '设备押金')
        this.userForm.depositId = this.depositList[0].id
      } else {
        this.$message.success('请求超时请稍后重试')
      }
    },
    // 关闭弹框
    clossAddUser() {
      this.addUser = false
      this.userForm.ownerName = ''
      this.userForm.ownerPhone = ''
      this.userForm.roomId = ''
      this.userForm.propertyAgentPay = 0
    },
    // 获取物业所有房屋
    async getPropertyRoomList() {
      let result = await getpropertyRoomList()
      let floors = Object.values(result.data.floors) //对象转化为数组
      if (result.success) {
        this.roomList = this.getDt(floors)
      }
    },
    //递归获取信息
    getDt(list) {
      let tree = []
      const that = this
      list.map((d) => {
        tree.push({
          value: d.room_name ? d.id : d.floorName ? d.id : d.unitName ? d.id : d.layerName ? d.id : '',
          label: d.room_name
            ? d.room_name + '室'
            : d.floorName
            ? d.floorName + '号楼'
            : d.unitName
            ? d.unitName + '单元'
            : d.layerName
            ? d.layerName + '层'
            : '',
          children: d.children ? that.getDt(d.children) : null
        })
      })
      return tree
    },
    close() {
      this.$emit('closeAddUser')
    },
    async getAddUser() {
      let params = {
        ...this.userForm,
        roomId: this.userForm.roomId[3]
      }
      this.addUserInfo(params)
      this.addUser = false
    },
    async getPay() {
      let params = {
        ...this.userForm,
        roomId: this.userForm.roomId[3]
      }
      this.userForm.roomId = this.userForm.roomId[3]
      let res = await getUserDepositMoneyPay(this.userForm)
      if (res.success) {
        window.open().document.write(res.data)
        this.addUser = false
        this.userForm.ownerName = ''
        this.userForm.ownerPhone = ''
        this.userForm.roomId = ''
        this.userForm.propertyAgentPay = 0
      } else {
        this.$message.success('请求超时请稍后重试')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
</style>
