<template>
  <el-container>
    <el-dialog class="openCall" v-el-drag-dialog :visible.sync="openCall" width="30%" top="80vh" @close="close" :modal="false" append-to-body :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false">
      <div class="content">
        <div class="number">603号楼1单元1102 桑先生</div>
        <div class="c_botBox" v-if="callType == 0">
          <div class="c_b_txt">来电</div>
          <div class="c_icon" @click="OpenInCallShow()">
            <img src="../../assets/img/call_green.svg" />
          </div>
        </div>
        <div class="c_botBox" v-if="callType == 1">
          <div class="c_b_txt">正在呼叫</div>
          <div class="c_icon" @click="OpenExCallShow">
            <img src="../../assets/img/call_red.svg" />
          </div>
        </div>
        <div class="c_botBox" v-if="callType == 2">
          <div class="c_b_txt">00:00</div>
          <div class="c_icon" @click="OpenExCallShow">
            <img src="../../assets/img/call_red.svg" />
          </div>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from "@/directive/el-dragDialog";
export default {
  props: ['openCall'],
  directives: {
    elDragDialog,
  },
  data () {
    return {
      callType: '0'
    }
  },
  methods: {
    close () {
      this.$emit('closeOpenCall')
    },
    OpenInCallShow () {
      this.callType = 2
    },
    OpenExCallShow () {
      this.globalCall.handOff()
      // this.openCall = false
    }
  }
}
</script>
<style lang="less">
.openCall {
  .el-dialog {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    position: fixed;
    right: 24px;
  }
  .el-dialog__body {
    padding: 0 30px 30px 30px;
  }
  .content {
    color: #202626;
    .number {
      font-size: 32px;
      font-weight: 500;
    }
    .c_botBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c_b_txt {
        font-size: 18px;
        font-weight: 500;
        opacity: 0.5;
      }
      .c_icon {
        width: 64px;
        height: 64px;
        cursor: pointer;
      }
    }
  }
}
</style>
