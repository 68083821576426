<template>
  <el-container>
    <el-dialog
      title="设备预警详情"
      v-el-drag-dialog
      :visible.sync="deviceWarn"
      width="50%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <!-- 报警信息 -->
        <div class="c_warnBox">
          <!-- 未解决 -->
          <div class="c_w_inner c_w_alertBox" v-if="warnBox == false">
            <div class="c_w_i_titBox">
              <span
                >{{ wdeviceDetailss.floorName }}号楼{{ wdeviceDetailss.unitName }}单元{{
                  wdeviceDetailss.layerName
                }}层{{ wdeviceDetailss.roomName }}室</span
              >
              <span> - </span>
              <span>{{ formatDate(wdeviceDetails.createTime) }}</span>
            </div>
            <div class="c_w_i_titBox">
              <span>{{ wdeviceDetails.alarmDevice }}</span>
              <span> - </span>
              <span
                >{{
                  wdeviceDetails.alarmContent == '55AA00015A' || wdeviceDetails.alarmContent == '55AA02015A'
                    ? '报警'
                    : wdeviceDetails.alarmContent == '55AA00025A' || wdeviceDetails.alarmContent == '55AA02025A'
                    ? '低电量报警'
                    : wdeviceDetails.alarmContent == '55AA00035A' || wdeviceDetails.alarmContent == '55AA02035A'
                    ? '心跳/恢复正常'
                    : wdeviceDetails.alarmContent == '55AA00045A' || wdeviceDetails.alarmContent == '55AA02045A'
                    ? '拆除'
                    : wdeviceDetails.alarmContent == '55AA00055A' || wdeviceDetails.alarmContent == '55AA02055A'
                    ? '测试'
                    : wdeviceDetails.alarmContent == '55AA00075A' || wdeviceDetails.alarmContent == '55AA02075A'
                    ? '故障'
                    : wdeviceDetails.alarmContent == '55AA00085A' || wdeviceDetails.alarmContent == '55AA02085A'
                    ? '温度报警'
                    : wdeviceDetails.alarmContent == '55AA000A5A' || wdeviceDetails.alarmContent == '55AA020A5A'
                    ? '心跳'
                    : wdeviceDetails.alarmContent == '55AA000D5A' || wdeviceDetails.alarmContent == '55AA020D5A'
                    ? '防拆恢复'
                    : '其它'
                }}异常</span
              >
            </div>
          </div>
          <!-- 已解决 -->
          <div class="c_w_inner c_w_securityBox" v-if="warnBox == true">
            <el-row class="c_b_inner">
              <div class="c_b_i_tit">报警处理记录</div>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>告警设备：</span>
                  <span>{{ wdeviceDetails.alarmDevice }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>告警内容：</span>
                  <span
                    >{{
                      wdeviceDetails.alarmContent == '55AA00015A' || wdeviceDetails.alarmContent == '55AA02015A'
                        ? '报警'
                        : wdeviceDetails.alarmContent == '55AA00025A' || wdeviceDetails.alarmContent == '55AA02025A'
                        ? '低电量报警'
                        : wdeviceDetails.alarmContent == '55AA00035A' || wdeviceDetails.alarmContent == '55AA02035A'
                        ? '心跳/恢复正常'
                        : wdeviceDetails.alarmContent == '55AA00045A' || wdeviceDetails.alarmContent == '55AA02045A'
                        ? '拆除'
                        : wdeviceDetails.alarmContent == '55AA00055A' || wdeviceDetails.alarmContent == '55AA02055A'
                        ? '测试'
                        : wdeviceDetails.alarmContent == '55AA00075A' || wdeviceDetails.alarmContent == '55AA02075A'
                        ? '故障'
                        : wdeviceDetails.alarmContent == '55AA00085A' || wdeviceDetails.alarmContent == '55AA02085A'
                        ? '温度报警'
                        : wdeviceDetails.alarmContent == '55AA000A5A' || wdeviceDetails.alarmContent == '55AA020A5A'
                        ? '心跳'
                        : wdeviceDetails.alarmContent == '55AA000D5A' || wdeviceDetails.alarmContent == '55AA020D5A'
                        ? '防拆恢复'
                        : '其它'
                    }}异常</span
                  >
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>告警时间：</span>
                  <span>{{ formatDate(wdeviceDetails.createTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>回电时间：</span>
                  <span>{{ formatDate(wdeviceDetails.recycleTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>解决时间：</span>
                  <span>{{ formatDate(wdeviceDetails.updateTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>是否报修：</span>
                  <span>{{ wdeviceDetails.repair == '0' && wdeviceDetails.repair == null ? '无' : '已报修' }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>报修原因：</span>
                  <span>{{ wdeviceDetails.reason }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理状态：</span>
                  <span>{{
                    wdeviceDetails.processingStatus != null && wdeviceDetails.processingStatus != '' ? '已处理' : ''
                  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理记录：</span>
                  <span>{{ wdeviceDetails.processingRecord }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>解决方：</span>
                  <span>{{ wdeviceDetails.resolver }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="c_baseBox">
          <el-row class="c_b_inner">
            <div class="c_b_i_tit">基本信息</div>
            <el-col :span="8">
              <div class="c_b_items">
                <span>业主姓名：</span>
                <span>{{ this.userNameStr(userProfileInfo.ownerName) }}先生/女士</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>联系电话：</span>
                <span>{{ this.mobileStr(userProfileInfo.ownerPhone) }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>开通方式：</span>
                <span>{{
                  userProfileInfo.openMethod == '0'
                    ? '自主开通'
                    : userProfileInfo.openMethod == '1'
                    ? '物业开通'
                    : '平台开通'
                }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>服务状态：</span>
                <span>{{ (userProfileInfo.serviceStatus = 1 ? '正常' : '异常') }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>申请时间：</span>
                <span>{{ userProfileInfo.createTime }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 家庭成员 -->
        <div class="c_familyBox">
          <div class="c_f_tit">家庭成员列表</div>
          <div class="c_f_content">
            <el-row :gutter="8">
              <el-col :span="6" v-for="(item, i) in memberList.rows" :key="i" class="c_f_c_grout">
                <div class="c_f_c_items">
                  <div class="c_f_c_i_tag">{{ item.owner_relationship }}</div>
                  <div class="avatar">
                    <img v-if="item.gender == '先生'" src="../../assets/img/avatar_sir.png" alt="gender" />
                    <img v-if="item.gender == '女士'" src="../../assets/img/avatar_MS.png" alt="gender" />
                  </div>
                  <div class="c_f_c_i_name">
                    <span :style="{ 'padding-right': '16px' }"
                      >{{ item.name != null ? userNameStr(item.name) : '' }}{{ item.gender }}
                    </span>
                    <span>{{ item.age }}岁</span>
                  </div>
                  <div class="c_f_c_i_dec">{{ item.chronic_disease }}</div>
                  <div class="c_f_c_i_dec">{{ item.disability_condition }}</div>
                  <div class="c_f_c_i_btn">
                    <div class="c_f_c_i_urgent btnCall" @click="callSelfPhone(item.emergency_contact_phone)">
                      紧急联系
                    </div>
                    <div class="c_f_c_i_call btnCall" @click="callSelfPhone(item.mobile)">本人电话</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="OpenCallShow()">拨打座机</el-button>
        <el-button type="primary" @click="OpenWarnSolve" v-if="resolver == null">解 决</el-button>
        <el-button type="primary" @click="OpenWarnReport" v-if="resolver == null">报 修</el-button>
      </span>
    </el-dialog>
    <!-- 解除报警 -->
    <warnSolve :warnSolve="warnSolveShow" @closeWarnSolve="showWarnSolve" :warnSolveInfo="warnSolveInfo"></warnSolve>
    <!-- 设备报修 -->
    <warnReport
      :warnReport="warnReportShow"
      @closeWarnReport="showWarnReport"
      :warnReportInfo="warnReportInfo"
    ></warnReport>
    <!-- 拨打电话/来电 -->
    <openCall :openCall="openCallShow" @closeOpenCall="showCallShow"></openCall>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
// 解除报警
import WarnSolve from '@/components/home/warnSolve'
// 设备报修
import WarnReport from '@/components/home/warnReport'
// 拨打电话
import OpenCall from '@/components/home/openCall'
// 居民服务信息
import { queryUserProfile, queryMemberById } from '@/api/archives.js'
// 解除警报
import { queryDeviceWarningPut } from '@/api/warning.js'
import { uploadFileVoice } from '@/api/call.js'

export default {
  props: ['deviceWarn', 'wdeviceDetails'],
  components: {
    WarnSolve: WarnSolve,
    WarnReport: WarnReport,
    OpenCall: OpenCall
  },
  directives: {
    elDragDialog
  },
  data() {
    return {
      warnBox: '',
      // 解除报警
      warnSolveShow: false,
      // 设备报修
      warnReportShow: false,
      // 拨打电话
      openCallShow: false,
      wdeviceDetailss: {},
      userInfo: {},
      query: {
        page: 1,
        pageSize: 10
      },
      memberList: [],
      // 事件Id
      eventId: '',
      roomId: '',
      // 基本信息
      userProfileInfo: {},
      // 解决方
      resolver: ''
    }
  },
  watch: {
    wdeviceDetails(n, o) {
      this.wdeviceDetailss = n
      this.eventId = n.id
      this.roomId = n.roomId
      this.resolver = n.resolver
      if (n.resolver != null) {
        this.warnBox = true
      } else {
        this.warnBox = false
      }
      this.getMemberList()
      this.getUserProfile()
    }
  },
  methods: {
    async upload(e) {
      const file = e.target.files[0]
      let formData = new FormData()
      formData.append('file', file)
      let res = await uploadFileVoice(formData)
    },
    close() {
      this.$emit('closeDeviceWarn')
    },
    // 获取家庭成员列表
    async getMemberList() {
      let res = await queryMemberById(this.roomId, this.query)
      this.memberList = res.data
    },
    // 解除报警
    OpenWarnSolve() {
      this.warnSolveShow = true
    },
    showWarnSolve() {
      this.warnSolveShow = false
    },
    //解除报警
    async warnSolveInfo(data) {
      let result = await queryDeviceWarningPut(this.eventId, data)
      if (result.success) {
        if (result.success) {
          this.$message.success('解除报警成功')
          this.deviceWarn = false
        }
      }
    },
    // 设备报修
    OpenWarnReport() {
      this.warnReportShow = true
    },
    showWarnReport() {
      this.warnReportShow = false
    },
    async warnReportInfo(data) {
      let result = await queryDeviceWarningPut(this.eventId, data)
      if (result.success) {
        this.$message.success('设备报修成功')
        this.deviceWarn = false
      }
    },
    // 获取设备报警所属服务信息
    async getUserProfile() {
      let result = await queryUserProfile(this.roomId)
      if (result.success) {
        this.userProfileInfo = result.data
      }
    },
    // 拨打电话
    OpenCallShow() {
      let that = this
      let phoneNum = this.wdeviceDetails.ownerPhone
      if (!phoneNum) {
        return this.$message.error('缺少电话号码')
      }
      this.globalCall.callPhone(phoneNum)
      window.cp.cpRecord.wdeviceDetails = this.wdeviceDetails
      window.cp.cpRecord.userInfo = this.userProfileInfo
      window.cp.cpRecord.phone = phoneNum
      window.cp
        .createCall({
          phone: phoneNum,
          callType: 1,
          wdeviceDetails: this.wdeviceDetails,
          userInfo: this.userProfileInfo
        })
        .show()
    },
    callSelfPhone(phoneNum) {
      if (!phoneNum) {
        return this.$message.error('缺少电话号码')
      }
      window.cp.cpRecord.wdeviceDetails = this.wdeviceDetails
      window.cp.cpRecord.userInfo = this.userProfileInfo
      window.cp.cpRecord.phone = phoneNum
      window.cp
        .createCall({
          phone: phoneNum,
          callType: 1,
          wdeviceDetails: this.wdeviceDetails,
          userInfo: this.userProfileInfo
        })
        .show()
    },
    showCallShow() {
      this.openCallShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  max-height: 500px;
  overflow: auto;
}
.c_w_inner {
  padding: 16px;
  margin-bottom: 16px;
  .c_w_i_titBox {
    padding: 8px 0;
    span {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }
  }
}
.btnCall {
  cursor: pointer;
}
.c_w_alertBox {
  background: #e55e5e;
}
.c_w_securityBox {
  background: #d2f8e5;
}
.c_baseBox {
  padding: 16px;
  border: 1px solid #c7cfcf;
}
.c_b_i_tit {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}
.c_b_items {
  padding: 8px 0;
}
.c_f_tit {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
}
.c_f_c_grout {
  margin-bottom: 8px;
}
.c_f_c_items {
  border: 1px solid #c7cfcf;
  text-align: center;
  height: 276px;
  .c_f_c_i_tag {
    position: absolute;
    background: #09bebe;
    padding: 0 16px;
    line-height: 28px;
    height: 28px;
    color: #fff;
  }
  .avatar {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .c_f_c_i_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
  }
  .c_f_c_i_dec {
    padding-bottom: 16px;
    font-size: 12px;
    span {
      color: #dd2424;
      padding: 2px 4px;
      background: #f8d2d2;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .c_f_c_i_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px 16px;
    .c_f_c_i_urgent {
      color: #dd2424;
      width: 44%;
      line-height: 32px;
      border-radius: 4px;
      background: #fbe9e9;
    }
    .c_f_c_i_call {
      color: #09be64;
      width: 44%;
      line-height: 32px;
      background: #e9fbf2;
      border-radius: 4px;
    }
  }
}
</style>
