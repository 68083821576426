<template>
  <el-container>
    <el-dialog
      title="解除报警"
      v-el-drag-dialog
      :visible.sync="warnSolve"
      width="20%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="solveForm" ref="solveForm">
          <div class="item_vlaue">处理结果</div>
          <div class="item_key">
            <div class="item_key">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入简单描述处理结果"
                v-model="solveForm.processingRecord"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="warnSolve = false">取 消</el-button>
        <el-button type="primary" @click="getWarnSolveInfo()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
export default {
  props: ['warnSolve', 'warnSolveInfo'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      solveForm: {
        //解除警报
        processingRecord: '',
        processingStatus: '1',
        //解决方
        resolver: '物业解决'
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeWarnSolve')
    },
    async getWarnSolveInfo() {
      this.warnSolveInfo(this.solveForm)
      this.warnSolve = false
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
