import request from '@/utils/request'

// 来电预警、设备预警


// 查询来电预警列表
export function queryCallWarning(data) {
  console.log(data)
  return request({
    url: '/property/callWarning/list',
    method: 'POST',
    data: data
  })
}
// 来电数据统计查询
export function queryCallDataList(resolver, data) {
  console.log(data)
  return request({
    url: '/property/callWarning/resolverList/' + resolver,
    method: 'POST',
    data: data
  })
}
// 来电处理时长统计
export function queryCallProcessingTimeList(data, processingTime) {
  console.log(data)
  return request({
    url: '/property/callWarning/ProcessingTimeList/' + processingTime,
    method: 'POST',
    data: data
  })
}
// 来电本月新增
export function queryCallThisMonthEventList(data) {
  console.log(data)
  return request({
    url: '/property/callWarning/callEventList/thisMonth',
    method: 'POST',
    data: data
  })
}
// 查询设备预警列表
export function queryDeviceWarning(data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/list',
    method: 'POST',
    data: data
  })
}
// 设备数据统计查询
export function queryDeviceDataList(resolver, data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/resolverList/' + resolver,
    method: 'POST',
    data: data
  })
}
// 设备报修次数
export function queryRepairDataList(repair, data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/repairList/' + repair,
    method: 'POST',
    data: data
  })
}
// 设备未解决报警
export function queryIsNullEventList(data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/isNullEventList',
    method: 'POST',
    data: data
  })
}
// 设备处理时长统计
export function queryDeviceProcessingTimeList(data, processingTime) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/ProcessingTimeList/' + processingTime,
    method: 'POST',
    data: data
  })
}
// 本月新增
export function queryThisMonthEventList(data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/deviceEventList/thisMonth',
    method: 'POST',
    data: data
  })
}
// 解决/报修预警
export function queryDeviceWarningPut(id, data) {
  console.log(data)
  return request({
    url: '/property/deviceWarning/' + id,
    method: 'PUT',
    data: data
  })
}

// 消息通知
// 根据消息roomId查询所属物业
export function queryRoomIdToProperty(roomId) {
  return request({
    url: '/property/roomIdToProperty/' + roomId,
    method: 'get',
  })
}

// 获取当地信息
export function getLocationAds() {
  return request({
    url: 'http://pv.sohu.com/apiSohu/cityjson?ie=utf-8',
    method: 'get',
  })
}