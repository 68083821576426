import request from '@/utils/request'

// 设备管理

// 根据物业查询所有智能设备列表
export function getPropertySmartDeviceList (data) {
  console.log(data)
  return request({
    url: '/property/smartDevice/listAll',
    method: 'post',
    data: data
  })
}
// 根据roomId查询智能设备列表
export function getSmartDeviceList (roomId, data) {
  console.log(data)
  return request({
    url: '/property/smartDevice/' + roomId,
    method: 'post',
    data: data
  })
}
// 根据物业查询所有智能设备列表
export function getSmartPhoneList (roomId) {
  return request({
    url: '/property/smartPhone/' + roomId,
    method: 'get',
  })
}
// 根据roomId查询智能设备列表
export function getPropertySmartPhoneList (data) {
  return request({
    url: '/property/smartPhone/smartPhoneList',
    method: 'post',
    data: data
  })
}

