import request from '@/utils/request'



// 根据物业查询外呼记录
export function queryCallOuter(data) {
    return request({
        url: '/property/exhaleRecord',
        method: 'get',
        params:data
    })
}
// 查询设备预警列表
export function queryDeviceWarning(data) {
    console.log(data)
    return request({
        url: '/property/deviceWarning/list',
        method: 'POST',
        data:data
    })
}


// 呼入记录列表
export function queryCallInList(data) {
    return request({
        url: 'property/callWarning/list',
        method: 'POST',
        data:data
    })
}
// 查询设备预警列表
// export function queryDeviceWarning(data) {
//     console.log(data)
//     return request({
//         url: '/property/deviceWarning/list',
//         method: 'POST',
//         data:data
//     })
// }


// 通话记录 —— 录音
export function uploadFileVoice(data) {
    return request({
        url: 'property/callWarning/callMediaAdd/file',
        method: 'POST',
        data:data
    })
}


// 通过手机号获取用户信息
export function getPhoneInfo(phone) {
    // phone = 13700000000
    return request({
        url: 'property/familyMember/mobile/' + phone,
        method: 'POST',
    })
}



// 根据roomId查询服务
export function queryUserProfile (roomId) {
    console.log(roomId)
    return request({
      url: '/property/userProfile/' + roomId,
      method: 'GET'
    })
  }

// 解决弹窗-来电
export function postSoloveProblem (data) {
    return request({
      url: '/property/callWarning',
      method: 'POST',
      data:data
    })
  }  

// 解决弹窗-回访
export function postSoloveProblemBack (data) {
    return request({
      url: '/property/exhaleRecord',
      method: 'POST',
      data:data
    })
}   