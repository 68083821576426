<template>
  <el-container>
    <el-dialog
      title="电话详情"
      v-el-drag-dialog
      :visible.sync="callWarn"
      width="50%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <!-- 报警信息 -->
        <div class="c_warnBox">
          <!-- 未解决 -->
          <div class="c_w_inner c_w_alertBox" v-if="callWarnShow == false">
            <div class="c_w_i_titBox">
              <span
                >{{ callInfo.floorName }}号楼{{ callInfo.unitName }}单元{{ callInfo.layerName }}层{{
                  callInfo.roomName
                }}</span
              >
              <span> - </span>
              <span>{{ formatDate(callInfo.createTime) }}</span>
            </div>
            <div class="c_w_i_titBox">
              <span>来电未接听</span>
            </div>
          </div>
          <!-- 已解决 -->
          <div class="c_w_inner c_w_securityBox" v-if="callWarnShow == true">
            <el-row class="c_b_inner">
              <div class="c_b_i_tit">通话记录</div>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电电话：</span>
                  <span>{{ callInfo.incomingCall }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>被叫电话：</span>
                  <span>{{ callInfo.calledTelephone }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电开始时间：</span>
                  <span>{{ formatDate(callInfo.callStartTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电结束时间</span>
                  <span>{{ formatDate(callInfo.callEndTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>通话记录：</span>
                  <span>播放录音</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理状态：</span>
                  <span>{{ callInfo.processingStatus == 1 ? '已处理' : '-' }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理记录：</span>
                  <span>{{ callInfo.processingRecord }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>解决方：</span>
                  <span>{{ callInfo.resolver }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="c_baseBox">
          <el-row class="c_b_inner">
            <div class="c_b_i_tit">基本信息</div>
            <el-col :span="8">
              <div class="c_b_items">
                <span>业主姓名：</span>
                <span>{{ userNameStr(userProfileInfo.ownerName) }}先生/女士</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>联系电话：</span>
                <span>{{ mobileStr(userProfileInfo.ownerPhone) }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>开通方式：</span>
                <span>{{
                  userProfileInfo.openMethod == '0'
                    ? '自主开通'
                    : userProfileInfo.openMethod == '1'
                    ? '物业开通'
                    : '平台开通'
                }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>服务状态：</span>
                <span>{{ (userProfileInfo.serviceStatus = '0' ? '待安装' : '正常') }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>申请时间：</span>
                <span>{{ userProfileInfo.createTime }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 家庭成员 -->
        <div class="c_familyBox">
          <div class="c_f_tit">家庭成员列表</div>
          <div class="c_f_content">
            <el-row :gutter="8">
              <el-col :span="6" v-for="(item, i) in memberList.rows" :key="i" class="c_f_c_grout">
                <div class="c_f_c_items">
                  <div class="c_f_c_i_tag">{{ item.owner_relationship }}</div>
                  <div class="avatar">
                    <img v-if="item.gender == '先生'" src="../../assets/img/avatar_sir.png" alt="gender" />
                    <img v-if="item.gender == '女士'" src="../../assets/img/avatar_MS.png" alt="gender" />
                  </div>
                  <div class="c_f_c_i_name">
                    <span :style="{ 'padding-right': '16px' }"> {{ userNameStr(item.name) }}{{ item.gender }} </span>
                    <span>{{ item.age }}岁</span>
                  </div>
                  <div class="c_f_c_i_dec">{{ item.chronic_disease }}</div>
                  <div class="c_f_c_i_dec">{{ item.disability_condition }}</div>
                  <div class="c_f_c_i_btn">
                    <div class="c_f_c_i_urgent btnCall" @click="callSelfPhone(item.emergency_contact_phone)">
                      紧急联系
                    </div>
                    <div class="c_f_c_i_call btnCall" @click="callSelfPhone(item.mobile)">本人电话</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="callPhone">拨打座机</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
// 居民服务信息
import { queryUserProfile, queryMemberById } from '@/api/archives.js'
export default {
  props: ['callWarn', 'callDetails'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      callWarnShow: '',
      callInfo: {},
      // 基本信息
      userProfileInfo: {},
      memberList: [],
      roomId: '',
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  watch: {
    callDetails(n, o) {
      this.callInfo = n
      this.roomId = n.roomId
      if (n.resolver != null) {
        this.callWarnShow = true
      } else {
        this.callWarnShow = false
      }
      this.getUserProfile()
      this.getMemberList()
    }
  },
  methods: {
    callPhone() {
      let that = this
      let phoneNum = this.userProfileInfo.ownerPhone
      if (!phoneNum) {
        return this.$message.error('缺少电话号码')
      }
      this.globalCall.callPhone(phoneNum)

      window.cp.cpRecord.userInfo = this.userProfileInfo
      window.cp.cpRecord.phone = phoneNum

      window.cp.createCall({ phone: phoneNum, callType: 1, userInfo: this.callInfo }).show()
    },
    callSelfPhone(phoneNum) {
      if (!phoneNum) {
        return this.$message.error('缺少电话号码')
      }
      this.globalCall.callPhone(phoneNum)
      window.cp.cpRecord.userInfo = this.userProfileInfo
      window.cp.cpRecord.phone = phoneNum
      window.cp.createCall({ phone: phoneNum, callType: 1, userInfo: this.callInfo }).show()
    },
    close() {
      this.$emit('closeCallWarn')
    },
    // 获取设备报警所属服务信息
    async getUserProfile() {
      let result = await queryUserProfile(this.roomId)
      if (result.success) {
        this.userProfileInfo = result.data
      }
    },
    // 获取家庭成员列表
    async getMemberList() {
      let res = await queryMemberById(this.roomId, this.query)
      this.memberList = res.data
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  max-height: 500px;
  overflow: auto;
}
.c_w_inner {
  padding: 16px;
  margin-bottom: 16px;
  .c_w_i_titBox {
    padding: 8px 0;
    span {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }
  }
}
.btnCall {
  cursor: pointer;
}
.c_w_alertBox {
  background: #e55e5e;
}
.c_w_securityBox {
  background: #d2f8e5;
}
.c_baseBox {
  padding: 16px;
  border: 1px solid #c7cfcf;
}
.c_b_i_tit {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}
.c_b_items {
  padding: 8px 0;
}
.c_f_tit {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
}
.c_f_c_grout {
  margin-bottom: 8px;
}
.c_f_c_items {
  border: 1px solid #c7cfcf;
  text-align: center;
  height: 276px;
  .c_f_c_i_tag {
    position: absolute;
    background: #09bebe;
    padding: 0 16px;
    line-height: 28px;
    height: 28px;
    color: #fff;
  }
  .avatar {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .c_f_c_i_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
  }
  .c_f_c_i_dec {
    padding-bottom: 16px;
    font-size: 12px;
    span {
      color: #dd2424;
      padding: 2px 4px;
      background: #f8d2d2;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .c_f_c_i_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px 16px;
    .c_f_c_i_urgent {
      color: #dd2424;
      width: 44%;
      line-height: 32px;
      border-radius: 4px;
      background: #fbe9e9;
    }
    .c_f_c_i_call {
      color: #09be64;
      width: 44%;
      line-height: 32px;
      background: #e9fbf2;
      border-radius: 4px;
    }
  }
}
</style>
