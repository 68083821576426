import request from '@/utils/request'

// 账户管理 - 查询全部列表运营账号
export function queryAcList(data) {
    return request({
        url: '/sys/account',
        method: 'get',
        params:data
    })
}

// 账户管理 - 新增
export function createForm(data) {
    console.log(data)
    return request({
        url: '/sys/account',
        method: 'POST',
        data:data
    })
}


// 账户管理 - 删除
export function delAccount(id) {
    return request({
        url: '/sys/account/' + id,
        method: 'delete',
    })
}

// 账户管理 - 更新
export function updateAccount(data) {
    return request({
        url: '/sys/account/'+data.id,
        method: 'put',
        data:data

    })
}

// 物业注册 
export function accountRegister(data) {
    return request({
        url: '/property/propertyReview/register',
        method: 'POST',
        data:data

    })
}

// 上传 
export function fileUpload(data) {
    return request({
        url: '/property/propertyReview/upload/file',
        method: 'POST',
        data:data
    })
}