<template>
  <el-container>
    <el-dialog
      title="待安装"
      v-el-drag-dialog
      :visible.sync="reviewed"
      width="50%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <!-- 基本信息 -->
        <div class="c_baseBox">
          <el-row class="c_b_inner">
            <div class="c_b_i_tit">基本信息</div>
            <el-col :span="8">
              <div class="c_b_items">
                <span>业主姓名：</span>
                <span>{{ userNameStr(userReviewInfo.ownerName) }}先生/女士</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>联系电话：</span>
                <span>{{ mobileStr(userReviewInfo.ownerPhone) }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>开通方式：</span>
                <span>{{
                  userReviewInfo.openMethod == '0'
                    ? '自主开通'
                    : userReviewInfo.openMethod == '1'
                    ? '物业开通'
                    : '平台开通'
                }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>支付方式：</span>
                <span>{{ userReviewInfo.paymentMethod == '0' ? '未支付' : '已支付' }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>服务状态：</span>
                <span>{{ (userReviewInfo.serviceStatus = '0' ? '待安装' : '服务正常') }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>申请时间：</span>
                <span>{{ formatDate(userReviewInfo.createTime) }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reviewed = false">关闭</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
export default {
  props: ['reviewed', 'userReviewInfos'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      userReviewInfo: {}
    }
  },
  watch: {
    userReviewInfos(n, o) {
      this.userReviewInfo = n
    }
  },
  methods: {
    close() {
      this.$emit('closeReviewed')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  max-height: 500px;
  overflow: auto;
}
.c_w_inner {
  padding: 16px;
  margin-bottom: 16px;
  .c_w_i_titBox {
    padding: 8px 0;
    span {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }
  }
}
.c_w_alertBox {
  background: #e55e5e;
}
.c_w_securityBox {
  background: #d2f8e5;
}
.c_baseBox {
  padding: 16px;
  border: 1px solid #c7cfcf;
}
.c_b_i_tit {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}
.c_b_items {
  padding: 8px 0;
}
.c_f_tit {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
}
.c_f_c_items {
  border: 1px solid #c7cfcf;
  text-align: center;
  .c_f_c_i_tag {
    position: absolute;
    background: #09bebe;
    padding: 4px 16px;
    color: #fff;
  }
  .avatar {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .c_f_c_i_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
  }
  .c_f_c_i_dec {
    padding-bottom: 16px;
    font-size: 12px;
    span {
      color: #dd2424;
      padding: 2px 4px;
      background: #f8d2d2;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .c_f_c_i_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px 16px;
    .c_f_c_i_urgent {
      color: #dd2424;
      width: 44%;
      line-height: 32px;
      border-radius: 4px;
      background: #fbe9e9;
    }
    .c_f_c_i_call {
      color: #09be64;
      width: 44%;
      line-height: 32px;
      background: #e9fbf2;
      border-radius: 4px;
    }
  }
}
</style>
