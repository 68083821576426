import request from '@/utils/request'

// 物业管理 - 成员管理列表
export function queryMemberList (data) {
  return request({
    url: '/property/department',
    method: 'get',
    params: data
  })
}

// 物业管理 - 成员管理列表 - 新增成员
export function addMemberApi (data) {
  return request({
    url: '/property/department',
    method: 'POST',
    data: data
  })
}
// 物业管理 - 成员管理列表 - 更新成员
export function updateMemberApi (data, id) {
  return request({
    url: '/property/department/' + id,
    method: 'put',
    data: data
  })
}

// 物业管理 - 成员管理列表 - 删除成员
export function delMemberApi (id) {
  return request({
    url: '/property/department/' + id,
    method: 'delete',
  })
}
// 物业管理 - 成员管理列表 - 开启消息提醒
export function addTimeoutReminder (data,id) {
  return request({
    url: '/property/department/timeoutReminder/'+id,
    method: 'POST',
    data: data
  })
}
// 物业管理 - 楼号管理 - 批量保存
export function batchSave (data) {
  return request({
    url: '/property/batchSave',
    method: 'POST',
    data: data
  })
}
// 物业管理 - 房号管理 - 单个新增
export function addRoomName (data) {
  return request({
    url: '/property/house/room',
    method: 'post',
    data: data
  })
}
// 物业管理 - 房号管理 - 编辑
export function putRoomName (id,data) {
  return request({
    url: '/property/house/room/'+id,
    method: 'put',
    data: data
  })
}
// 物业管理 - 房号管理 - 删除
export function deleteRoomName (id) {
  return request({
    url: '/property/house/room/'+id,
    method: 'delete',
  })
}
// 物业管理 - 楼层管理 - 单个新增
export function addLayerName (data) {
  return request({
    url: '/property/house/layer',
    method: 'post',
    data: data
  })
}
// 物业管理 - 楼层管理 - 编辑
export function putLayerName (id,data) {
  return request({
    url: '/property/house/layer/'+id,
    method: 'put',
    data: data
  })
}
// 物业管理 - 楼层管理 - 删除
export function deleteLayerName (id) {
  return request({
    url: '/property/house/layer/'+id,
    method: 'delete',
  })
}
// 物业管理 - 单元管理 - 单个新增
export function addUnitName (data) {
  return request({
    url: '/property/house/unit',
    method: 'post',
    data: data
  })
}
// 物业管理 - 单元管理 - 编辑
export function putUnitName (id,data) {
  return request({
    url: '/property/house/unit/'+id,
    method: 'put',
    data: data
  })
}
// 物业管理 - 单元管理 - 删除
export function deleteUnitName (id) {
  return request({
    url: '/property/house/unit/'+id,
    method: 'delete',
  })
}
// 物业管理 - 楼号管理 - 单个新增
export function addHouseName (data) {
  return request({
    url: '/property/house',
    method: 'post',
    data: data
  })
}
// 物业管理 - 楼号管理 - 编辑
export function putHouseName (id,data) {
  return request({
    url: '/property/house/'+id,
    method: 'put',
    data: data
  })
}
// 物业管理 - 楼号管理 - 删除
export function deleteHouseName (id) {
  return request({
    url: '/property/house/'+id,
    method: 'delete',
  })
}
// 物业管理 - 小区管理 - 获取基本信息
export function getAreaInfo (id) {
  return request({
    url: '/property/' + id,
    method: 'get',
  })
}

// 物业管理 - 更新小区基本信息 - 获取基本信息
export function updateAreaInfo (id, data) {
  return request({
    url: '/property/' + id,
    method: 'put',
    data: data
  })
}

// 物业管理 - 获取小区房屋列表
export function getpropertyRoomList () {
  return request({
    url: '/property/hostList',
    method: 'get'
  })
}
// 物业管理 - 合同管理 - 获取基本信息
export function getContractFile (id) {
  return request({
    url: '/property/contract/' + id,
    method: 'get',
  })
}
// 物业管理 - 房屋管理 - 查询所有信息
export function getHoseTotal () {
  return request({
    url: '/property/hostList',
    method: 'get',
  })
}
// 物业管理 - 房屋管理 - 所有房屋总数
export function getRoomAllList (data) {
  return request({
    url: '/property/roomAllList',
    method: 'POST',
    data: data
  })
}

// 系统公告 - 查询全部分类
export function queryNoticeClassList () {
  return request({
    url: `/property/notice/classList`,
    method: 'get'
  })
}
// 系统公告 - 根据classId查询内容
export function queryNoticeClassContentList (classId,data) {
  return request({
    url: `/property/notice/` + classId,
    method: 'get',
    params: data
  })
}
